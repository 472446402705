import React from "react"
import { Link } from "gatsby"

import { IconLogo } from "../icons/IconLogo"
import { FORM } from "../providers/urls"
import {
  LOCATION__TYPE__NURSINGROOM,
  LOCATION__TYPE__BATHROOM,
  NAVIGATION__RECOMMEND,
  SITENAME__DOMAINNAME,
  NAVIGATION__ABOUT,
  SITENAME__CCTLD,
} from "../providers/texts"

import styles from "./Header.module.scss"

interface Props {
  resetState?: () => void
  locationType?: string
  subtitle?: string
}

const Header: React.FC<Props> = ({
  resetState,
  locationType = "MAIN_SITE_HEADER",
  subtitle = "Mapa prostorů určených k péči o děti",
}) => {
  const handleHeaderClick = () => {
    if (resetState) {
      resetState()
    }
  }

  const generateTitle = (locationType: string) => {
    switch (locationType) {
      case "BABYROOM":
        return <> {LOCATION__TYPE__NURSINGROOM}</>
      case "BATHROOM":
        return (
          <>
            {LOCATION__TYPE__BATHROOM.split(" ")[0]}
            <span className={styles.title__bathroom__contrast}>
              {LOCATION__TYPE__BATHROOM.split(" ")[1]}
            </span>
          </>
        )
      case "MAIN_SITE_HEADER":
      default:
        return (
          <>
            {SITENAME__DOMAINNAME}
            <span className={styles.title__mainHeader__contrast}>
              {SITENAME__CCTLD}
            </span>
          </>
        )
    }
  }

  const generateSubtitleStyles = (subtitle: string) => {
    const longSubtitle = subtitle && subtitle.length > 37
    return `${styles.subtitle} ${longSubtitle && styles.subtitleLong}`
  }

  const generateLargeScreensNavigation = (locationType: string) => {
    if (locationType === "MAIN_SITE_HEADER") {
      return (
        <div className={styles.navigation}>
          <Link className={styles.link} to="/about/">
            {NAVIGATION__ABOUT}
          </Link>
          <a
            className={styles.link}
            href={FORM}
            target="_blank"
            rel="noreferrer"
          >
            {NAVIGATION__RECOMMEND}
          </a>
        </div>
      )
    }
  }

  return (
    <div className={styles.container}>
      <button
        aria-label={SITENAME__DOMAINNAME + SITENAME__CCTLD}
        className={styles.header}
        onClick={() => handleHeaderClick()}
      >
        <IconLogo />
        <div className={styles.text}>
          <div className={styles.title}>{generateTitle(locationType)}</div>
          <div className={generateSubtitleStyles(subtitle)}>{subtitle}</div>
        </div>
      </button>

      {generateLargeScreensNavigation(locationType)}
    </div>
  )
}

export { Header }
