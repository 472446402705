const GEOLOCATION_URL = "https://geolocation-db.com/json/"
const MAPTILER_MAPYCZ_URL = "https://www.mapy.cz"
const MAPTILER_MAPTILER_URL = "https://www.maptiler.com"
const MAPTILER_OSM_URL = "https://www.openstreetmap.org/"

const EMAIL = "mailto:barbora@ruzickova.net"
const WEBSITE = "https://www.ruzickova.net"
const FACEBOOK = "https://www.facebook.com/prebalovnycz"
const FORM = "https://forms.gle/w95j1dBTxDfXvPZD9"

const GOOGLE_ANALYTICS = "https://analytics.google.com/"
const HOTJAR = "https://www.hotjar.com"
const BLOCK_COOKIES = "https://jakzablokovatcookies.cz/"

export {
  GEOLOCATION_URL,
  MAPTILER_MAPTILER_URL,
  MAPTILER_MAPYCZ_URL,
  MAPTILER_OSM_URL,
  EMAIL,
  WEBSITE,
  FACEBOOK,
  GOOGLE_ANALYTICS,
  HOTJAR,
  BLOCK_COOKIES,
  FORM,
}
