import React from "react"

const IconLogo: React.FC = () => {
  return (
    <svg
      width="28"
      height="28"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 280 396"
    >
      <g transform="translate(0.000000,396.000000) scale(0.100000,-0.100000)">
        <path
          d="M1225 3950 c-588 -76 -1075 -540 -1180 -1125 -21 -114 -20 -304 1
-415 72 -386 398 -1026 931 -1830 249 -375 398 -580 424 -580 31 0 501 688
741 1085 320 531 513 934 594 1248 50 190 42 421 -21 642 -34 121 -140 331
-220 436 -197 263 -484 446 -814 520 -100 22 -348 33 -456 19z m291 -556 c27
-9 56 -23 65 -30 8 -8 20 -14 25 -14 22 0 107 -99 137 -159 28 -58 31 -71 30
-160 0 -76 -5 -106 -22 -143 -86 -188 -283 -279 -472 -217 -264 87 -347 400
-162 619 86 102 264 148 399 104z m-592 -578 c18 -7 50 -36 71 -62 147 -187
350 -257 558 -191 100 32 159 74 259 185 63 69 153 80 218 25 70 -59 80 -139
26 -218 -43 -65 -128 -147 -202 -195 -101 -67 -93 -52 -96 -182 -2 -116 -2
-117 26 -145 75 -74 128 -176 146 -280 17 -93 0 -225 -39 -304 -44 -92 -133
-123 -216 -76 -45 26 -85 87 -85 133 0 17 7 51 15 74 21 61 19 125 -7 177 -18
36 -26 43 -43 38 -157 -45 -170 -45 -317 2 -12 3 -24 -5 -38 -28 -22 -35 -22
-76 -4 -249 5 -47 2 -60 -18 -89 -34 -51 -71 -73 -125 -78 -118 -10 -193 112
-193 315 0 137 46 256 137 353 l43 46 0 119 -1 119 -72 47 c-87 57 -169 135
-223 213 -34 50 -39 65 -38 110 1 114 112 186 218 141z"
        />
        <path
          d="M1122 2137 c9 -136 55 -211 163 -263 140 -67 320 8 369 154 9 25 16
79 16 119 l0 73 -276 0 -277 0 5 -83z"
        />
      </g>
    </svg>
  )
}

export { IconLogo }
