const SITENAME = "Přebalovny.cz"
const SITENAME__DOMAINNAME = "Přebalovny"
const SITENAME__CCTLD = ".cz"
const SITE__CONTACT = "barbora@ruzickova.net"
const SITE__ABOUT_PAGE = "Informace o projektu"

const MAPTILEPROVIDER__MAPYCZ = "Mapy.cz"
const MAPTILEPROVIDER__MAPTILER = "MapTiler"
const MAPTILEPROVIDER__OSM = "Open Street Map"
const MAPTILEPROVIDER__COPYRIGHT_NOTICE = "Mapové dlaždice © "

const NAVIGATION__ABOUT = "O projektu"
const NAVIGATION__RECOMMEND = "Doporuč přebalovnu!"

const COOKIES = "Cookies"
const COOKIE_CONSENT =
  "Tyto stránky využívají k analýze návštěvnosti a zlepšování uživatelského zážitku soubory cookies. Jejich užíváním s tímto souhlasíte"
const COOKIE_CONSENT__MORE_INFO = "více informací"
const COOKIE_CONSENT__UNDERSTAND = "Rozumím"

const GEOLOCATION__MAP_CANNOT_BE_CENTERED =
  "Mapu nelze vycentrovat. Aktuální poloha není k dispozici."
const GEOLOCATION__NOT_SUPPORTED =
  "Prohlížeč nepodporuje geolokaci. " + GEOLOCATION__MAP_CANNOT_BE_CENTERED
const GEOLOCATION__CANNOT_FIND_CLOSEST =
  "Aktuální poloha není k dispozici. Nejbližší přebalovnu tedy bohužel není možné určit. 😟"

const GEOLOCATION__DISABLED =
  "Zdá se, že Vaše zařízení nepovoluje využívání údajů o poloze."

const LOCATION__TYPE__NURSINGROOM = "Přebalovna"
const LOCATION__TYPE__BATHROOM = "Bezbariérové WC"
const LOCATION__AMENITY__HASNAPPYCHANGINGUTILITIES = "Přebalovací pult"
const LOCATION__AMENITY__HASLOCK = "Lze zamknout"
const LOCATION__AMENITY__HASRUNNINGWATER = "Tekoucí voda"
const LOCATION__AMENITY__HASMICROWAVE = "Mikrovlnka"
const LOCATION__LAST_VISITED = "Naposledy navštíveno"

const LOCATION__TYPE__NURSINGROOM_FILTERLABEL = "Prostor určený k péči o děti"
const LOCATION__TYPE__BATHROOM_FILTERLABEL = "Bezbariérové veřejné WC"

const LOCATION__TYPE__NURSINGROOM_FILTERLABEL_SHORT = "Přebalovna"
const LOCATION__TYPE__BATHROOM_FILTERLABEL_SHORT = "Veřejné WC"
const LOCATION__TYPE__BATHROOM_FILTERLABEL_EXTRASHORT = "WC"

const BUTTON = "Tlačítko"
const BUTTON__CLOSEST = "Nejbližší"
const BUTTON__BACK = "Zpět"
const BUTTON__BACK_TO_MAP = "Zpět k mapě"

const PAGE_NOT_FOUND = "Stránka nenalezena"
const LOCATION_PHOTO = "Fotografie přebalovny"

const ERROR = "Chybová hláška: "

const WITHIN_PRAGUE_BOUNDS =
  "Mapa přebaloven pokrývá v tuto chvíli primárně Prahu."
const WITHIN_PRAGUE_BOUNDS__CURRENT_LOCATION =
  WITHIN_PRAGUE_BOUNDS +
  "Svou přesnou polohu na mapě uvidíte jen pokud se právě nacházíte v hlavním městě."
const NO_LOCATION_VISIBLE =
  'Na mapě se nezobrazují žádné přebalovny, nemůžeme z nich tedy vybrat nejbližší 😟. Zkuste změnit nastavení filtrů! Třeba zakliknout možnost "Prostor určený k péči o děti".'

const ZOOM_IN = "Přiblížit mapu"
const ZOOM_OUT = "Oddálit mapu"
const CENTER_MAP = "Vycentrovat mapu"
const DISPLAY_FILTERS = "Zobrazit filtry"
const DISPLAY_ABOUT_PAGE = "Zobrazit informace o projektu"
const DISPLAY_DETAIL_GALLERY = "Zobrazit galerii"
const SHARE = "Sdílet"
const NEXT = "Další"
const PREVIOUS = "Předchozí"

const LOADING = "Načítá se..."

const ATTRIBUTIONS = "Copyright"

function FIND_CLOSEST_LOCATION(
  distanceToClosestLocation: string,
  title: string,
  type: string,
  found: boolean
): string {
  return found
    ? `Nejbližší ${
        type === "BABYROOM" ? "přebalovnu" : "WC s přebalovacím pultem"
      } najdete cca. ${distanceToClosestLocation} km daleko (${title}).`
    : `V okolí se bohužel nenachází žádná přebalovna. Nejbližší najdete cca. ${distanceToClosestLocation} km daleko (${title}).`
}

export {
  PAGE_NOT_FOUND,
  SITE__ABOUT_PAGE,
  LOCATION_PHOTO,
  GEOLOCATION__NOT_SUPPORTED,
  GEOLOCATION__MAP_CANNOT_BE_CENTERED,
  GEOLOCATION__CANNOT_FIND_CLOSEST,
  LOCATION__TYPE__BATHROOM,
  LOCATION__TYPE__NURSINGROOM,
  LOCATION__AMENITY__HASNAPPYCHANGINGUTILITIES,
  LOCATION__AMENITY__HASLOCK,
  LOCATION__AMENITY__HASRUNNINGWATER,
  LOCATION__AMENITY__HASMICROWAVE,
  LOCATION__LAST_VISITED,
  LOCATION__TYPE__BATHROOM_FILTERLABEL,
  LOCATION__TYPE__NURSINGROOM_FILTERLABEL,
  LOCATION__TYPE__NURSINGROOM_FILTERLABEL_SHORT,
  LOCATION__TYPE__BATHROOM_FILTERLABEL_SHORT,
  LOCATION__TYPE__BATHROOM_FILTERLABEL_EXTRASHORT,
  BUTTON__CLOSEST,
  BUTTON__BACK,
  BUTTON__BACK_TO_MAP,
  SITENAME,
  SITENAME__CCTLD,
  SITENAME__DOMAINNAME,
  SITE__CONTACT,
  NAVIGATION__ABOUT,
  NAVIGATION__RECOMMEND,
  MAPTILEPROVIDER__MAPTILER,
  MAPTILEPROVIDER__MAPYCZ,
  MAPTILEPROVIDER__OSM,
  MAPTILEPROVIDER__COPYRIGHT_NOTICE,
  FIND_CLOSEST_LOCATION,
  COOKIES,
  COOKIE_CONSENT,
  COOKIE_CONSENT__UNDERSTAND,
  COOKIE_CONSENT__MORE_INFO,
  ERROR,
  WITHIN_PRAGUE_BOUNDS,
  WITHIN_PRAGUE_BOUNDS__CURRENT_LOCATION,
  ZOOM_IN,
  ZOOM_OUT,
  CENTER_MAP,
  DISPLAY_FILTERS,
  DISPLAY_ABOUT_PAGE,
  DISPLAY_DETAIL_GALLERY,
  SHARE,
  NEXT,
  PREVIOUS,
  BUTTON,
  NO_LOCATION_VISIBLE,
  LOADING,
  ATTRIBUTIONS,
  GEOLOCATION__DISABLED,
}
